import { useState } from "react"

import { List, ListItem } from "@material-ui/core"

import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  FilterWrapper,
  ParadiseLayout,
  ParadiseTitle,
  PillsWrapper,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { useFetchParadiseFirmwares } from "src/data/paradise/paradiseFirmwares/queries/paradiseFirmwareQueries"
import { IParadiseFirmware } from "src/data/paradise/paradiseFirmwares/types/paradiseFirmwareQueryTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { spacing } from "src/ui/spacing"
import { EntriesTypeCheck } from "src/utils/tsUtil"

const tableColumns: TableColumn<IParadiseFirmware>[] = [
  {
    value: "name",
    label: "Name",
    columnWidth: "auto",
    disableClickPropagation: true,
    renderCell: (data) => (
      <InternalLink to={Routes.ParadiseFirmware.location(data.name.toString())}>
        {data.name}
      </InternalLink>
    ),
  },
  {
    value: "description",
    label: "Description",
    columnWidth: "auto",
    renderCell: (data) => <div>{data.description}</div>,
  },
  {
    value: "tags",
    label: "Tags",
    columnWidth: "auto",
    renderCell: (data) => (
      <PillsWrapper>
        {data.tags.map((tag) => (
          <MBadge key={tag}>{tag}</MBadge>
        ))}
      </PillsWrapper>
    ),
  },
  {
    value: "mcu",
    label: "MCU",
    columnWidth: "auto",
    renderCell: (data) => (
      <div>{getFirmwareDescriptionValue("mcu", data.description)}</div>
    ),
  },
  {
    value: "wlan",
    label: "WLAN",
    columnWidth: "auto",
    renderCell: (data) => (
      <div>{getFirmwareDescriptionValue("wifi", data.description)}</div>
    ),
  },
  {
    value: "hardware",
    label: "Hardware",
    columnWidth: "auto",
    renderCell: (data) => (
      <div>{getFirmwareDescriptionValue("board", data.description)}</div>
    ),
  },
  {
    value: "Size",
    label: "size",
    columnWidth: "auto",
    renderCell: (data) => <div>{data.size}</div>,
  },
  {
    value: "download",
    label: "",
    columnWidth: "auto",
    disableClickPropagation: true,
    renderCell: (data) => (
      <div>
        {data.location_uri ? (
          <a download href={data.location_uri}>
            Export
          </a>
        ) : null}
      </div>
    ),
  },
]

const availableHardwares = {
  Amarillo: 200,
  Eureka: 300,
  P2: 100,
  P1: 1,
} as const

export function ParadiseFirmwares() {
  const { navigate } = useRouter()

  const [selectedHardware, setSelectedHardware] = useState<{
    name: keyof typeof availableHardwares
    version: number
  }>({
    name: "Amarillo",
    version: availableHardwares.Amarillo,
  })

  const fetchFirmwares = useFetchParadiseFirmwares({
    filter: {
      hardware_version: selectedHardware.version,
    },
    options: {
      // Setting stale time due heavy API which doesn't update often
      staleTime: 1000 * 60 * 10,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  })

  const { headerElements, rows, templateColumns } = useTableColumns({
    columns: tableColumns,
    data: fetchFirmwares.data?.firmwares,
  })

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Firmwares
            <MBadge color="info">
              Total:
              {fetchFirmwares.isSuccess &&
                fetchFirmwares.data?.firmwares.length}
            </MBadge>
          </ParadiseTitle>
        }
      />
      <TopWrapper>
        <FilterWrapper>
          <DropdownButton
            displayValue={`Hardware: ${selectedHardware.name}`}
            size="small"
            color="default"
            placement="bottom-start"
          >
            {({ closeMenu }) => (
              <List>
                {(
                  Object.entries(availableHardwares) as EntriesTypeCheck<
                    typeof availableHardwares
                  >
                ).map(([name, versionNumber]) => (
                  <ListItem
                    onClick={() => {
                      setSelectedHardware({
                        name,
                        version: versionNumber,
                      })
                      closeMenu()
                    }}
                    key={versionNumber}
                    button
                  >
                    <span style={{ marginLeft: "1ch" }}>{name}</span>
                  </ListItem>
                ))}
              </List>
            )}
          </DropdownButton>
        </FilterWrapper>
      </TopWrapper>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          if (fetchFirmwares.data?.firmwares) {
            navigate(
              Routes.ParadiseFirmware.location(
                // @ts-expect-error: noUncheckedIndexedAccess
                fetchFirmwares.data.firmwares[index].name.toString()
              )
            )
          }
        }}
        error={{
          hasError: fetchFirmwares.isError,
          title: fetchFirmwares.error?.message,
        }}
      />
    </ParadiseLayout>
  )
}

// This function gets the "key" value from the description, description is a string of format "someKey=someValue otherKey=otherValue"
function getFirmwareDescriptionValue(key: string, description: string) {
  for (const section of description.split(" ")) {
    const splittedSection = section.split("=")
    const leftPart = splittedSection[0]
    const rightPart = splittedSection[1]

    if (leftPart === key) {
      return rightPart
    }
  }

  return null
}
